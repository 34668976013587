@use 'books';
@use 'navbar';

@import 'variables';

@import url("https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.4.2/css/all.min.css");
@import "~bootstrap/scss/bootstrap";



@font-face {
    font-family: 'abaddon';
    src: url('./fonts/abaddon.TTF') format('truetype');
}

* {
    box-sizing: border-box;
    transition: all 0.1s ease-in-out;
    scroll-behavior: smooth;
}

body {
    background-image: url('../../../public/assets/img/bg-asset.jpg');
    background-repeat: repeat;
    background-color: $black;
}

.vhelkan-title {
    font-family: 'abaddon', 'Courier New', Courier, monospace;
    font-size: 5rem;
    text-shadow: 0px 2px 10px $white;
}

.vhelkan-subtitle {
    display: flex;

    span {
        padding-left: 1rem;
        display: block;
    }
}

a {
    color: $white;
    text-decoration: none;

    &:hover {
        color: $red;
    }
}

.social-icons {
    text-align: center;

    a {
        font-size: xx-large;
        margin: 1rem;
    }
}

/* HOME - HERO */

.hero-container {
    .hero-image {
        background-image: url("../../../public/assets/img/vhelkanzoto-hero.jpg");
        height: 60vh;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        position: relative;
        border-bottom: 1px solid $white;

        .hero-text {
            position: absolute;
            width: 100%;
            color: white;
            display: flex;
            justify-content: space-between;
            height: 100%;
            align-items: center;

            .not-available {
                cursor: not-allowed;
                color: $lightGray;

                &:hover {
                    color: $white;
                }
            }
        }
    }
}

/* PREVIEW */

.headline {
    height: 40vh;
    color: $white;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    &--title {
        text-align: center;
    }
}

/* Footer */

#footer {
    align-self: flex-end;
    width: 100%;
    padding: 1.5em 0 0 0;
    color: rgba(255, 255, 255, 0.75);
    cursor: default;
    text-align: center;
    text-transform: none;
    padding-bottom: 1.2rem;
}

#footer .copyright {
    margin: 0;
    padding: 0;
    font-size: 0.9em;
    list-style: none;
}

#footer .copyright li {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 0 0 0.45em;
    padding: 0 0 0 0.85em;
    line-height: 1;
}

#footer .copyright li:first-child {
    border-left: 0;
}

.jaes-made-it {
    text-transform: none;

    a {
        color: $red;
        text-decoration: none;

        &:hover {
            color: $white;
            font-weight: 600;
        }
    }
}

/* RESPONSIVENESS */

@media (max-width: 767.98px) {
    .vhelkan-subtitle {
        flex-direction: column;

        span {
            text-align: end;
        }
    }
}