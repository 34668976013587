@import 'variables';

#books {
    .card {
        background-color: $transparentWhite;
        padding: 1rem;
    }

    .card-body {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding-top: 0px;
        padding-bottom: 0px;

        .card-text {
            text-align: justify;
        }
    }
}

// @media screen and (max-width: 480px) {

// }