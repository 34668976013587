@import 'variables';


.navbar-brand {
    .vhelkan-title {
        font-size: 1.5rem;
        text-shadow: none;
    }
}

.navbar-nav {

    a {
        color: $white;
        text-decoration: none;

        &:hover {
            color: $red;
        }

        &.not-available {
            cursor: not-allowed;
            color: $lightGray;

            &:hover {
                color: $white;
            }
        }

        &.nav-link.active {
            color: $red;
            font-weight: bold;
        }
    }
}